import StatusHeader from './statusHeader.json';
import ContentRecord from './contentRecord.json';
import ContentMakeDetail from './contentMakeDetail.json';
import ContentCriterion from './contentCriterion.json';
import ContentByStatus from './contentByStatus.json';
import ContenMakeStatusDetail from './contenMakeStatusDetail.json';

export default {
    ...StatusHeader,
    ...ContentRecord,
    ...ContentMakeDetail,
    ...ContentCriterion,
    ...ContentByStatus,
    ...ContenMakeStatusDetail,
}
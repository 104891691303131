import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './axios'
import setAuthHeader from './axios'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import i18n from '@/locales';
import { createPinia } from 'pinia'

const pinia = createPinia()

if (localStorage.token) {
    setAuthHeader(localStorage.token)
} else {
    setAuthHeader(false)
}
// createApp(App).use(store).use(router).use(ElementPlus).mount('#app')
const app = createApp(App)

app.use(store)
app.use(router)
app.use(ElementPlus)
app.use(i18n)
app.use(pinia)

app.mount('#app')

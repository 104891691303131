import PositionAuthority from "./PositionAuthority.json";
import PositionAuthorityAdd from "./PositionAuthorityAdd.json";
import PositionAuthorityDetail from "./PositionAuthorityDetail.json";
import StaffsAdd from "./StaffsAdd.json";
import StaffsDetail from "./StaffsDetail.json";
import StaffsView from "./StaffsView.json";

export default {
  ...PositionAuthority,
  ...PositionAuthorityAdd,
  ...PositionAuthorityDetail,
  ...StaffsAdd,
  ...StaffsDetail,
  ...StaffsView,
};

import InventoryRecord from "./InventoryRecord.json";
import RestockStatus from "./RestockStatus.json";
import RestockStatusDetail from "./RestockStatusDetail.json";
import RestockStatusRecord from "./RestockStatusRecord.json";
import RestockStatusRecordDetail from "./RestockStatusRecordDetail.json";
import StockOut from "./StockOut.json";
import StockOutDetail from "./StockOutDetail.json";
import StockOutRecord from "./StockOutRecord.json";
import StockOutRecordDetail from "./StockOutRecordDetail.json";
import WarehouseInventory from "./WarehouseInventory.json";
import WarehouseRecord from "./WarehouseRecord.json";
import RemainderAdd from "./RemainderAdd.json";

export default {
  ...InventoryRecord,
  ...RestockStatus,
  ...RestockStatusDetail,
  ...RestockStatusRecord,
  ...RestockStatusRecordDetail,
  ...StockOut,
  ...StockOutDetail,
  ...StockOutRecord,
  ...StockOutRecordDetail,
  ...WarehouseInventory,
  ...WarehouseRecord,
  ...RemainderAdd
};

import TypeTabs from './typeTabs.json';
import ElVendorSelect from './elVendorSelect.json';
import components from './components.json';
import header from './header.json';
import map from './map.json';
import other from './other.json';
import storehouse from './storehouse.json';
import select from './select.json';
import globalAlert from './globalAlert.json';
import globalNavbar from './globalNavbar.json';

export default {
    ...TypeTabs,
    ...ElVendorSelect,
    ...components,
    ...header,
    ...map,
    ...other,
    ...storehouse,
    ...select,
    ...globalAlert,
    ...globalNavbar
}
import Cookies from 'js-cookie'

export function getLanguage() {
  return Cookies.get('language')
}

export function setLanguage(lang) {
  return Cookies.set('language', lang)
}


// import cookieSignature from 'cookie-signature'

// export function getLanguage() {
//   const signedCookie = document.cookie.replace(/(?:(?:^|.*;\s*)language\s*=\s*([^;]*).*$)|^.*$/, "$1");
//   return cookieSignature.unsign(signedCookie, process.env.VUE_APP_COOKIE_SECRET);
// }

// export function setLanguage(lang) {
//   const signedValue = cookieSignature.sign(lang, process.env.VUE_APP_COOKIE_SECRET);
//   document.cookie = `language=${signedValue}; path=/; secure; samesite=strict`;
// } 

import packItem from './packItem.json';
import packRecord from './packRecord.json';
import packStatus from './packStatus.json';
import packMakeDetail from './packMakeDetail.json';

export default {
    ...packItem,
    ...packRecord,
    ...packStatus,
    ...packMakeDetail
};
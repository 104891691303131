import Overage from './overage.json';
import PickRecord from './pickRecord.json';
import PickDetailModal from './pickDetailModal.json';
import PickMutipleModal from './pickMutipleModal.json';
import PickAdd from './pickAdd.json';

export default {
    ...Overage,
    ...PickRecord,
    ...PickDetailModal,
    ...PickMutipleModal,
    ...PickAdd
};
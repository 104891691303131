import ContentMake from "./make/contentMake";
import ContentStatus from "./make/contentStatus";
import PackMake from "./make/packMake";
import PackStatus from "./make/packStatus";
import Temporary from "./make/temporary";
import Form from "./form";
import Management from "./management";
import Storehouse from "./storehouse";
import System from "./system";
import Goods from "./goods";

export default {
  ...ContentMake,
  ...ContentStatus,
  ...PackMake,
  ...PackStatus,
  ...Temporary,
  ...Form,
  ...Management,
  ...Storehouse,
  ...System,
  ...Goods
};

import MaterialView from "./materialView.json";
import MaterialDetail from "./materialDetail.json";
import CreateAndUser from "./createAndUser.json";
import PackComponents from "./packComponents.json";
import PackView from "./packView.json";
import PackDetail from "./packDetail.json";
import PackAdd from "./packAdd.json";
import ContentView from "./contentView.json";
import ContentDetail from "./contentDetail.json";
import ContentAdd from "./contentAdd.json";
import MaterialModal from "./materialModal.json";
import SingleProduct from "./singleProduct.json";
import SingleProductAdd from "./singleProductAdd.json";
import SingleStepTwo from "./singleStepTwo.json";
import SingleStepThree from "./singleStepThree.json";
import ContentModal from "./contentModal.json";
import QuickyGift from "./quickyGift.json";
import QuickySingle from "./quickySingle.json";
import PackModal from "./packModal.json";
import SingleProductDetail from "./singleProductDetail.json";
import PackTable from "./packTable.json";
import giftProduct from "./giftProduct.json";
import giftProductDetail from "./giftProductDetail.json";
import giftProductAdd from "./giftProductAdd.json";
import quickyContent from "./quickyContent.json";
import packTableModal from "./packTableModal.json";
import packAddTableModal from "./packAddTableModal.json";
import materialAdd from "./materialAdd.json";

export default {
    ...MaterialView,
    ...MaterialDetail,
    ...CreateAndUser,
    ...PackComponents,
    ...PackView,
    ...PackDetail,
    ...PackAdd,
    ...ContentView,
    ...ContentDetail,
    ...ContentAdd,
    ...MaterialModal,
    ...SingleProduct,
    ...SingleProductAdd,
    ...SingleStepTwo,
    ...SingleStepThree,
    ...ContentModal,
    ...QuickyGift,
    ...QuickySingle,
    ...PackModal,
    ...SingleProductDetail,
    ...PackTable,
    ...giftProduct,
    ...giftProductDetail,
    ...giftProductAdd,
    ...quickyContent,
    ...packTableModal,
    ...packAddTableModal,
    ...materialAdd
};
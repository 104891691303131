import { createI18n } from 'vue-i18n';
import { getLanguage } from '@/utils/cookies';
import elementEnLocale from './lang/en.json';
import elementZhTWLocale from './lang/zh-tw.json';
import elementViLocale from './lang/vi.json';
import ComponentsTw from './lang/zh-tw/components';
import ComponentsEn from './lang/en/components';
import ComponentsVi from './lang/vi/components';
import ViewsTw from './lang/zh-tw/views';
import ViewsEn from './lang/en/views';
import ViewsVi from './lang/vi/views';
import UtilsTw from './lang/zh-tw/utils';
import UtilsEn from './lang/en/utils';
import UtilsVi from './lang/vi/utils';
import FilterOptionsTw from './lang/zh-tw/filter';
import FilterOptionsEn from './lang/en/filter';
import FilterOptionsVi from './lang/vi/filter';

const messages = {
  'en': {
    ...elementEnLocale,
    ...ComponentsEn,
    ...ViewsEn,
    ...UtilsEn,
    ...FilterOptionsEn
  },
  'zh-tw': {
    ...elementZhTWLocale,
    ...ComponentsTw,
    ...ViewsTw,
    ...UtilsTw,
    ...FilterOptionsTw
  },
  'vi': {
    ...elementViLocale,
    ...ComponentsVi,
    ...ViewsVi,
    ...UtilsVi,
    ...FilterOptionsVi
  },
};

export const getLocale = () => {
  const cookieLanguage = getLanguage();
  if (cookieLanguage) {
    return cookieLanguage;
  }
  const language = navigator.language.toLowerCase();
  const locales = Object.keys(messages);
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }

  return 'en';
};

const i18n = createI18n({
  legacy: false,
  locale: getLocale(),
  messages: messages,
  globalInjection: true,
});

document.title = i18n.global.t('manufacturersName');

export default i18n;
